import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Learning each day, and living with joy and happiness`}</p>
    </blockquote>
    <h3><em parentName="h3">{`artist, consultant, cyclist, designer, musician, photographer, world traveller`}</em></h3>
    <p>{`I live in Sydney, and love cycling, photography, music, and travel (not necessarily in that order).
Lately I have been learning how to design and code.`}</p>
    <p>{`This website is writen in `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com"
      }}>{`Gatsby`}</a>{` based on the
`}<a parentName="p" {...{
        "href": "https://cara.lekoarts.de"
      }}>{`Cara`}</a>{` theme by `}<a parentName="p" {...{
        "href": "https://www.lekoarts.de/en/"
      }}>{`LekoArts`}</a>{`.
The source code for this website is available on
`}<a parentName="p" {...{
        "href": "https://github.com/ChristineTham/christham-portfolio"
      }}>{`Github`}</a>{` and it is hosted on
Gastby.`}</p>
    <p>{`The websites featured on this page have been written in a mixture of React,
NextJS and Wordpress (using my own Genesis child themes).`}</p>
    <p>{`I am available for work should you wish to hire me.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      