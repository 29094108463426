import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Portfolio`}</h2>
    <ProjectCard title="Learning Jamstack" link="https://learning-jamstack.now.sh" bg="linear-gradient(to right, #F0047F 0%, #ec809e 100%)" image="learning-jamstack.jpg" mdxType="ProjectCard">
  My adventures learning how to build websites and apps in 2020 using the Jamstack architecture, auto workflows and modern build
  tools.
    </ProjectCard>
    <ProjectCard title="ChrisTham.net" link="https://christham.net" bg="linear-gradient(to right, #b565a7 0%, #be9cc1 100%)" image="christham.jpg" mdxType="ProjectCard">
  My personal web site.
    </ProjectCard>
    <ProjectCard title="Hello Tham" link="https://hellotham.com" bg="linear-gradient(to right, #660099 0%, #cc3366 100%)" image="hellotham.jpg" mdxType="ProjectCard">
  My company web site.
    </ProjectCard>
    <ProjectCard title="Travels Through A Lens" link="https://travel.christham.net" bg="linear-gradient(to right, #64bfa4 0%, #919bc9 100%)" image="travel.jpg" mdxType="ProjectCard">
  This is a web site that documents my travel adventures.
    </ProjectCard>
    <ProjectCard title="Visual Voyager" link="https://visualvoyager.net" bg="linear-gradient(to right, #27272a 0%, #a49e9e 100%)" image="visualvoyager.jpg" mdxType="ProjectCard">
  Explore the world we live in, as seen through our unique perspectives and experiences.
    </ProjectCard>
    <ProjectCard title="My Record Collection" link="https://christham-net.netlify.app/" bg="linear-gradient(to right, #F0047F 0%, #ec809e 100%)" image="my-record-collection.jpg" mdxType="ProjectCard">
  Explore my LP collection.
    </ProjectCard>
    <ProjectCard title="My Chakra Components" link="https://my-chakra-components.vercel.app/" bg="linear-gradient(to right, #b565a7 0%, #be9cc1 100%)" image="my-chakra-components.jpg" mdxType="ProjectCard">
  This site showcases example Chakra components (based on <a href="https://merakiui.com/">MerakiUI</a>
  but rewritten in <a href="https://chakra-ui.com/">chakra-ui</a> using the
  <a href="https://nextjs.org/">NextJS</a> framework and
  <a href="https://reactjs.org">React</a>.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      