import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say hi by sending me an `}<a parentName="p" {...{
        "href": "chris.tham@hellotham.com"
      }}>{`email`}</a>{`, or find me on other
platforms:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/christham/"
        }}>{`LinkedIn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ChristineTham"
        }}>{`Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/chris1.tham"
        }}>{`Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/Chris1Tham"
        }}>{`Twitter`}</a></li>
    </ul>
    <h3>{`Privacy Notice`}</h3>
    <p>{`This website does not collect any personal information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      